import React from 'react'

import Layout from '../components/Layout/Layout'
import Container from '../components/Container/Container'
import Seo from '../components/Seo/Seo'

import Try from '../sections/Try/Try'

const GdprPage = () => {
  return (
    <Layout pageName="gdpr">
      <Seo title="GDPR Commitment &amp; Roadmap" />
      <Container>
        <section className="data">
          <h1 className="heading data__heading">
            GDPR Commitment &amp; Roadmap
          </h1>
          <p>
            The GDPR (General Data Protection Regulation) is a piece of
            legislation that is designed to strengthen and unify data protection
            laws for all individuals within the European Union. The regulation
            became effective and enforceable on May 25, 2018.
          </p>
          <p>
            Ally is fully committed to achieving and upholding ongoing
            compliance with GDPR prior to the effective date.
          </p>

          <h2 className="heading data__heading-sm">
            What we’re doing about the GDPR
          </h2>
          <p>
            Ally began researching and pursuing compliance in 2019. The GDPR is
            a complex piece of legislation and we’ve been working with privacy
            experts and our attorneys to be sure we’re compliant with the GDPR.
            The privacy and security of our customers (and their customers) are
            of utmost importance to us..
          </p>
          <p>Here’s a high-level overview of our GDPR Compliance Roadmap:</p>
          <ul>
            <li>
              Appoint a Data Protection Officer – <strong>COMPLETED</strong>
            </li>
            <li>
              Thoroughly research the areas of our product and business impacted
              by GDPR – <strong>COMPLETED</strong>
            </li>
            <li>
              Rewrite our Data Protection Agreement – <strong>COMPLETED</strong>
            </li>
            <li>
              Develop a strategy and guidelines for how to address the areas of
              our product impacted by GDPR – <strong>COMPLETED</strong>
            </li>
            <li>
              Perform the necessary changes/improvements to our product based on
              the requirements – <strong>COMPLETED</strong>
            </li>
            <li>
              Implement the required changes to our internal processes and
              procedures required to achieve and maintain compliance with GDPR –{' '}
              <strong>COMPLETED</strong>
            </li>
            <li>
              Thoroughly test all of changes to verify & validate compliance
              with GDPR – <strong>COMPLETED</strong>
            </li>
            <li>
              Communicate our compliance – <strong>COMPLETED</strong>
            </li>
          </ul>

          <h2 className="heading data__heading-sm">
            Changes to Ally to become GDPR compliant
          </h2>
          <p>
            There are dozens upon dozens of changes and steps we’re taking
            across every part of our company to ensure we are GDPR compliant.
            This includes anonymizing more data, reducing the types of data
            shared across vendors to only the parts that are absolutely
            necessary and providing more controls over what data is/isn’t
            processed.
          </p>
          <p>
            We will also provide more tools for you to get your data out of
            Ally.
          </p>

          <h2 className="heading data__heading-sm">What is the GDPR?</h2>
          <p>
            The General Data Protection Act (GDPR) is considered to be the most
            significant piece of European data protection legislation to be
            introduced in the European Union (EU) in 20 years and will replace
            the the 1995 Data Protection Directive.
          </p>
          <p>
            The GDPR regulates the processing of personal data about individuals
            in the European Union including its collection, storage, transfer or
            use. Importantly, under the GDPR, the concept of “personal data” is
            very broad and covers any information relating to an identified or
            identifiable individual (also called a “data subject”).
          </p>
          <p>
            It gives data subjects more rights and control over their data by
            regulating how companies should handle and store the personal data
            they collect. The GDPR also raises the stakes for compliance by
            increasing enforcement and imposing greater fines should the
            provisions of the GDPR be breached.
          </p>
          <p>
            The GDPR enhances EU individuals’ privacy rights and places
            significantly enhanced obligations on organizations handling data.
          </p>
          <p>
            In summary, here are some of the key changes to come into effect
            with the upcoming GDPR:
          </p>
          <ul>
            <li>
              <strong>Expanded rights for individuals</strong>: The GDPR
              provides expanded rights for individuals in the European Union by
              granting them, among other things, the right to be forgotten and
              the right to request a copy of any personal data stored in their
              regard.
            </li>
            <li>
              <strong>Compliance obligations</strong>: The GDPR requires
              organizations to implement appropriate policies and security
              protocols, conduct privacy impact assessments, keep detailed
              records on data activities and enter into written agreements with
              vendors.
            </li>
            <li>
              <strong>Data breach notification and security</strong>: The GDPR
              requires organizations to report certain data breaches to data
              protection authorities, and under certain circumstances, to the
              affected data subjects. The GDPR also places additional security
              requirements on organizations.
            </li>
            <li>
              <strong>New requirements for profiling and monitoring</strong>:
              The GDPR places additional obligations on organizations engaged in
              profiling or monitoring behavior of EU individuals.
            </li>
            <li>
              <strong>Increased enforcement</strong>: Under the GDPR,
              authorities can fine organizations up to the greater of €20
              million or 4% of a company’s annual global revenue, based on the
              seriousness of the breach and damages incurred. Also, the GDPR
              provides a central point of enforcement for organizations with
              operations in multiple EU member states by requiring companies to
              work with a lead supervisory authority for cross-border data
              protection issues.
            </li>
          </ul>
          <p>
            If you are a company outside the EU, this still affects you. The
            provisions of the GDPR apply to any organization that processes
            personal data of individuals in the European Union, including
            tracking their online activities, regardless of whether the
            organization has a physical presence in the EU.
          </p>

          <h2 className="heading data__heading-sm">Contact Us</h2>
          <p>
            Have a question or concern? Please email us at
            <a href="mailto:support@getally.com">support@getally.com</a>
          </p>
        </section>
        <Try />
      </Container>
    </Layout>
  )
}

export default GdprPage
